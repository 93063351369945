<template>
  <div class="say-hello">
    <div class="say-hello__cover" v-scroll-reveal>
      <img src="../assets/images/henry_bw.jpg" alt="" />
    </div>
    <div>
      <h1 class="say-hello__title" v-scroll-reveal>
        Hello, I'm <span class="naming-hero">Frontend Developer</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>
