<template>
  <nav :class="{ 'main-navbar': true, 'with-banner': haveBanner }">
    <div class="container">
      <div class="main-navbar__container">
        <div class="main-brand">
          <router-link class="brand-link" to="/">
            Henry Raygan
          </router-link>
        </div>

        <button class="burger-button" @click="showMenu">
          <ion-icon :name="currentIcon"></ion-icon>
        </button>

        <ul :class="{ navbar: true, show: isShowMenu }">
          <li class="navbar-item">
            <router-link to="/speaking" class="navbar-link">
              Speaking
            </router-link>
          </li>
          <li class="navbar-item" v-if="false">
            <router-link to="/about" class="navbar-link">
              About me
            </router-link>
          </li>
          <li class="navbar-item">
            <router-link to="/projects" class="navbar-link">
              Projects
            </router-link>
          </li>
          <li class="navbar-item">
            <router-link
              to="//www.linkedin.com/in/henry-raygan/"
              target="_blank"
              class="navbar-link"
            >
              <ion-icon name="logo-linkedin"></ion-icon>
            </router-link>
          </li>
          <li class="navbar-item">
            <router-link
              to="//www.instagram.com/henryraygan/"
              target="_blank"
              class="navbar-link"
            >
              <ion-icon name="logo-instagram"></ion-icon>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      haveBanner: false,
      isShowMenu: false,
      currentIcon: "menu-outline",
    };
  },
  methods: {
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
      this.currentIcon = this.isShowMenu ? "close-outline" : "menu-outline";
    },
  },
  watch: {
    ["$route"](to, from) {
      this.isShowMenu = false;
      this.currentIcon = "menu-outline";
      switch (this.$route.name) {
        case "Speaking":
        case "Portfolio":
          this.haveBanner = true;
          break;
        default:
          this.haveBanner = false;
      }
    },
  },
};
</script>
