<template>
  <section class="home-section">
    <div class="container">
      <Hero />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// <img alt="Vue logo" src="../assets/logo.png" />
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from "@/components/Navbar.vue";
import Hero from "@/components/Hero.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Hero,
  },
};
</script>
