import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./styles/main.scss";
import CursorFx from "@luxdamore/vue-cursor-fx";
import "@luxdamore/vue-cursor-fx/dist/CursorFx.css";
import VueScrollReveal from "vue-scroll-reveal";

Vue.use(CursorFx);
Vue.use(VueScrollReveal);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
